import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Request } from './views/Request';
import { ErrorPage } from './views/ErrorPage';

const App = () => (
    <Router>
        <Switch>
            <Route path='/failed-request' component={ErrorPage} />
            <Route path='/:itemId?' component={Request} />
        </Switch>
    </Router>
);

export default App;
