import React from 'react';
import { Redirect } from 'react-router-dom';
import { BronsonSpinner } from '@dh/bronson-react';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { RequestService } from './request.service';
import { useState } from 'react';
import { useEffect } from 'react';
import { appConfig } from '../../config';

export const Request: React.FC = () => {
    const service = useRef(new RequestService()).current;
    const [error, setError] = useState(false);
    const { itemId } = useParams();

    useEffect(() => {
        if (itemId) {
            service
                .sendStoreFrontData(itemId)
                .then((response) => {
                    if (response.redirect) {
                        window.location.assign(
                            `${appConfig.preapprovalUrl}/usados/?cid=${response.id}`,
                        );
                    } else {
                        window.location.assign(`${appConfig.preapprovalUrl}`);
                    }
                })
                .catch(() => window.location.assign(`${appConfig.preapprovalUrl}`));
        } else {
            setError(true);
        }
    }, [itemId, service]);

    return error ? (
        <Redirect to='/failed-request' />
    ) : (
        <BronsonSpinner
            config={{
                showSpinner: true,
                spinnerWrapperClassModifier: 'c-spinner--text',
                showSpinnerText: true,
                spinnerText: 'Estamos redirigiendo a la preautorización',
            }}
        />
    );
};
